.TopHeader{
  width:100%;
  height:50;
  border:2px solid #e9e9e9;
  border-bottom: 2px solidb #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 750px) {
    .TopHeader{
        width:100%;
        height:50;
        border:2px solid #e9e9e9;
        border-bottom: 2px solidb #e9e9e9;
        display: none;
        justify-content: center;
        align-items: center;
      }
  }