.banner{
    height: 60vh;
    width: "100%";
    display: flex;
    justify-content: center;
    align-items:"center";
}


.banner .bannerImg{
 width: 80%;
 height:100%;
}

@media (max-width: 750px) {
    .banner{
        height: 30vh;
        width: "100%";
        display: flex;
        justify-content: center;
        align-items:"center";
    }

    .banner .bannerImg{
        width: 95%;
        height:70%;
       }
  }