.shopContainer{
    
}

.card-slider-container {
    width: 95%;
    margin: 0 auto ;
  }
  
  .card {
    background-color: #fff;
   
    border-radius: 8px;
    height: 350px;
    width: 50%;
    text-align: center;
    margin:  0 ,10px;
    padding: 10px;
    border: none;
    
  }

  .card .img-card{
   width: 100%;
   height: 200px;
   background-color: #dfe4ea;
   display: flex;
   justify-content: center;
   align-items: center;
   box-sizing: border-box;
   padding: 10px;
  }
  
  .card .img-card .img-item{
    height:170px;
    width: 200px;
  


  }

  .card .cartBtn{
    width: 100%;
    height: 40px;
   
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .card .cartBtn .btn{
    width: 150px;
    background-color: #991b1e;
    overflow: hidden;
    height: 40px;
    font-size: 13px;
    color: #fff;
    border-radius: 0;
    border: none;
    padding: 0px;
  }

  .card .cartBtn .btn .cart-text{
    width: "100%"; 
    height: 40px; 
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .nav-button:first-child {
    left: 10px;
  }
  
  .nav-button:last-child {
    right: 10px;
  }

  .computer-view{
    display: block;
  }
  .mobile-view{
    display: none;
  }

  .mobile-view .card-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

  }

  @media (max-width: 750px) {
    .mobile-view{
      display:block;
      
    }
    .computer-view{
      display: none;
    }
    .mobile-view .card-row{
      width: 95%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  
    }
  
   
  

    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
    
    .grid-item {
      padding: 5px;
      text-align: center;
    
    }

    .grid-item .img-card{
      width: 100%;
   height: 140px;
   background-color: #dfe4ea;
   display: flex;
   justify-content: center;
   align-items: center;
   box-sizing: border-box;
   padding: 10px;
    
    }
    .grid-item .img-card .img-item{
      height:100px;
      width: 100%;
    
    }
   
    .grid-item .cartBtn{
      width: 100%;
      height: 40px;
     
      display: flex;
      justify-content: center;
      align-items: center;
  
    }

    .grid-item .cartBtn .btn{
      width: 150px;
      background-color: #991b1e;
      overflow: hidden;
      height: 40px;
      font-size: 13px;
      color: #fff;
      border-radius: 0;
      border: none;
      padding: 0px;
    }

    .grid-item .cartBtn .btn .cart-text{
      width: "100%"; 
      height: 40px; 
      display: flex;
      justify-content: center;
      align-items: center;
    }
   
  }

  input::placeholder{
    color: #fff;
  }