
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    font-family: "Poppins", sans-serif;
}


.topHeader{
    width: calc(100% - 100px);
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.mobile-nav{
    display: none;
    width:100%;
    justify-content: center;
    align-items: center;
    height: 50px;
}

@media (max-width: 750px) {
    .topHeader{
        width: calc(100% - 100px);
        height: 120px;
        display:none;
        justify-content: center;
        align-items: center;
    }

    .mobile-nav{
        display: flex;
        width:100%;
        justify-content: center;
        align-items: center;
        height: 50px;
    }
  }
  .nav-elements-mo {
    position: absolute;
    left: 0;
    top: 50px;
    background-color: #030303;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    display: block;
  }

  .nav-elements-mo.active {
    width: 290px;
  }
